<template>
    <div class="loader_area3" v-if="habitTrackingLoader"><quote-loader/></div>
    <div class="profile_notes" v-else>
        <div class="title">
            <h3>Habits
                <button type="button" class="add_btn pointer ml-auto" @click="handleRefreshScores()"><i class="fa fa-sync" :class="{ 'fa-spin': refreshScoreLoader }"></i> {{ refreshScoreLoader ? 'Refreshing' : 'Refresh Score' }}</button>
            </h3>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" :disabled="habitTrackingLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <page-filter v-model="params.per_page" />
            </div>
        </div>
        <div class="notes_wpr">
            <ul class="card_listing" v-if="contactHabitTrackings.total">
                <li v-for="(habitTracking, p) of contactHabitTrackings.data" :key="p">
                    <profile-card v-bind:actions="actions" :action-handler="actionHandler" :selected-row="habitTracking">
                        <h4 class="mb-3 pointer" @click="viewHabit(habitTracking)"><img src="@/assets/images/drip.svg">{{ habitTracking.habit_tracking.name }}:</h4>
                        <div class="card_content">
                            <h5>Submissions:</h5>
                            <p>{{ habitTracking.submission_count }} / {{ habitTracking.occurrences_count }}</p>
                            <ul class="score">
                                <li>
                                    <h5>Participation Score:</h5>
                                    <p>{{habitTracking.participation_score}}%</p>
                                </li>
                                <li>
                                    <h5>Habit Score:</h5>
                                    <p>{{habitTracking.habit_score}}%</p>
                                </li>
                                <li>
                                    <h5>Best Streak:</h5>
                                    <p>{{habitTracking.best_streak}}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="card_footer">
                            <h5>Assigned on:</h5>
                            <span class="date">
                                {{ moment.utc(habitTracking.created_at).local().format('ll | LT') }}
                                <span class="text-danger" v-if="habitTracking.deleted_at">[deleted]</span>
                            </span>
                        </div>
                    </profile-card>
                </li>
            </ul>
            <div class="empty_list mt-3" v-if="contactHabitTrackings.total == 0 && !habitTrackingLoader">
                <img src="@/assets/images/empty_state.svg">
                <h4>No Records Found!</h4>
            </div>
        </div>
        <div class="pagination" v-show="contactHabitTrackings.total">
            <pagination v-model="params.page" :pages="contactHabitTrackings.last_page" @update:modelValue="handlePagination" />
        </div>
        <div class="form_view" v-if="showHabit">
            <button class="close_btn pointer" @click="showHabit = false"><i class="fas fa-times"></i></button>
            <habit-report v-model="showHabit" :habit-tracking="selectedRelation" :contact-relation="contact" />
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const HabitReport = defineAsyncComponent(() => import('@/pages/habit-tracking/components/HabitReport'))

    import moment from 'moment'

    export default {
        name: 'Tab Habit Tracking',

        data () {
            return {
                actions: [
                    {
                        icon: 'fa-search-plus',
                        label: 'View',
                        action: 'viewHabit'
                    },
                ],
                params: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: '',
                },
                moment,
                isTyping: false,
                showHabit: false,
                selectedRelation: {},
            };
        },

        props: {
            contact: Object,
        },

        components: {
            ProfileCard,
            HabitReport,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.getContactHabitTracking(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContactHabitTracking(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContactHabitTracking(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            habitTrackingLoader: state => state.habitTrackingModule.contactHabitTrackingLoader,
            contactHabitTrackings: state => state.habitTrackingModule.contactHabitTrackings,
            refreshScoreLoader: state => state.habitTrackingModule.refreshScoreLoader,
        }),

        mounted () {
            const vm = this;

            vm.params.contact_id = vm.contact.id;
            vm.getContactHabitTracking(vm.params);
        },

        methods: {
            ...mapActions({
                getContactHabitTracking: 'habitTrackingModule/getContactHabitTracking',
                refreshContactHabitsScore: 'habitTrackingModule/refreshContactHabitsScore',
            }),

            actionHandler (habit, action) {
                const vm = this;

                vm[action](habit);
            },

            viewHabit (habit) {
                const vm = this;

                vm.showHabit = true;
                vm.selectedRelation = habit.habit_tracking ? habit.habit_tracking : {};
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContactHabitTracking(vm.params);
            },

            handleRefreshScores () {
                const vm = this;

                vm.refreshContactHabitsScore(vm.contact.id).then((result) => {
                    if (result) {
                        vm.params.contact_id = vm.contact.id;
                        vm.getContactHabitTracking(vm.params);
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .notes_wpr{
        overflow-y: scroll;
        flex: 1;
    }
    .notes_wpr::-webkit-scrollbar {
        display: none;
    }
    .profile_notes .title h3{
        align-items: center;
        flex-grow: 1;
    }
    .card_listing .score{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .card_listing .score li{
        width: 50%;
        padding: 0 10px;
    }
    :deep(.form_view .report_section .habit_container){
        padding: 0 10px;
    }
    :deep(.form_view .loader_area.no_sidebar){
        position: static;
    }
    @media(max-width: 600px){
        .profile_notes .card_listing li{
            flex: 0 0 100%;
        }
        .profile_notes .title{
            flex-wrap: wrap;
        }
        .profile_notes .title h3{
            width: 100%;
            margin-bottom: 15px;
        }
        .profile_notes .title .right_side{
            width: 100%;
        }
        .profile_notes .title .right_side .search_panel{
            margin: 0 auto 0 0;
        }
    }
</style>
